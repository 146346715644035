import { Module } from "vuex";
import { AxiosResponse } from "axios";
import router from "@/router";
import { UserTypes } from "@/enums/UserTypes";
import { UserRoles } from "@/enums/UserRoles";
import useViewAsACustomerMessage from "@/composables/orders/useViewAsACustomerMessage";
import { quoteAllowedRoles } from "@/constants/roles/quoteAllowedRoles";
import { trackOrderAllowedRoles } from "@/constants/roles/trackOrderAllowedRoles";
import { rfqAllowedAdminRoles } from "@/constants/roles/rfqAllowedAdminRoles";
import { customersAllowedAdminRoles } from "@/constants/roles/customersAllowedAdminRoles";
import { checkoutAllowedRoles } from "@/constants/roles/checkoutAllowedRoles";
import { trackOrderCustomerAllowedAdminRoles } from "@/constants/roles/trackOrderCustomerAllowedAdminRoles";
import { trackOrderChatWithManufacturerAllowedAdminRoles } from "@/constants/roles/trackOrderChatWithManufacturerAllowedAdminRoles";
import { rfqRevisionAllowedRoles } from "@/constants/roles/rfqRevisionAllowedRoles";
import { quoteDeleteAllowedRoles } from "@/constants/roles/quoteDeleteAllowedRoles";
import { technicalFeedbackAllowedRoles } from "@/constants/roles/technicalFeedbackAllowedRoles";
import { rfqRevisionViewAllowedRoles } from "@/constants/roles/rfqRevisionViewAllowedRoles";
import { currencyAllowedAdminRoles } from "@/constants/roles/currencyAllowedAdminRoles";
import { technicalFeedbackOperationAllowedRoles } from "@/constants/roles/technicalFeedbackOperationAllowedRoles";
import { partInfoAllowedRoles } from "@/constants/roles/partInfoAllowedRoles";
import { partDetailsEditAllowedRoles } from "@/constants/roles/partDetailsEditAllowedRoles";
import { partDetailsDocumentsAllowedRoles } from "@/constants/roles/partDetailsDocumentsAllowedRoles";
import {
  changeIntercom,
  logoutIntercom,
} from "@/composables/intercomComposable";
import { uploadDataAllowedRoles } from "@/constants/roles/uploadDataAllowedRoles";
import { costingAllowedRoles } from "@/constants/roles/costingAllowedRoles";
import { partEditAllowedRoles } from "@/constants/roles/partEditAllowedRoles";
import { quoteCreateAllowedRoles } from "@/constants/roles/quoteCreateAllowedRoles";
import { trackOrderTechnicalFeedbackAllowedRoles } from "@/constants/roles/trackOrderTechnicalFeedbackAllowedRoles";
import { partDetailsVerifyAllowedRoles } from "@/constants/roles/partDetailsVerifyAllowedRoles";
import { costingParametersAllowedRoles } from "@/constants/roles/costingParametersAllowedRoles";
import { TeamRoles } from "@/enums/TeamRoles";
import { partDetailsCostingAllowedRoles } from "@/constants/roles/partDetailsCostingAllowedRoles";
import { rfqDownloadFilesAllowedAdminRoles } from "@/constants/roles/rfqDownloadFilesAllowedAdminRoles";
import { partConfirmationAllowedRoles } from "@/constants/roles/partConfirmationAllowedRoles";
import { addDiscountAllowedRoles } from "@/constants/roles/addDiscountAllowedRoles";
import { rfqDownloadCostingFileAllowedRoles } from "@/constants/roles/rfqDownloadCostingFileAllowedRoles";
import { sendTechnicalFeedbackNotificationAllowedRoles } from "@/constants/roles/sendTechnicalFeedbackNotificationAllowedRoles";
import { downloadEngineeringReportsAllowedRoles } from "@/constants/roles/downloadEngineeringReportsAllowedRoles";
import { reorderQuoteAllowedRoles } from "@/constants/roles/reorderQuoteAllowedRoles";
import { addAdjustmentAllowedRoles } from "@/constants/roles/addAdjustmentAllowedRoles";
import { viewAsAnotherUserAllowedRoles } from "@/constants/roles/viewAsAnotherUserAllowedRoles";
import UserInterface from "@/types/api/UserInterface";
import { RootState } from "@/types/store/RootState";
import { UserState } from "@/types/store/UserState";
import { SelectedCustomerInterface } from "@/types/customer/SelectedCustomerInterface";

const getDefaultState = () => {
  return {
    user: {} as UserInterface,
    accessToken: "",
    refreshToken: "",
    emailTimeout: "",
    email: "",
    refreshTokenRequest: null,
    isSidebarCollapsed: true,
    isViewAsACustomer: false,
    isShowViewAsACustomerMessage: false,
    viewAsACustomerData: {} as SelectedCustomerInterface,
  } as UserState;
};

const {
  showViewAsACustomerMessage,
  destroyViewAsACustomerMessage,
  messageReactive,
} = useViewAsACustomerMessage();

const store: Module<UserState, RootState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getAccessToken(state: UserState) {
      return state.accessToken;
    },
    getRefreshToken(state: UserState) {
      return state.refreshToken;
    },
    getRefreshTokenRequest(state: UserState) {
      return state.refreshTokenRequest;
    },
    getUserId(state: UserState) {
      return state.user.id;
    },
    getUser(state: UserState) {
      return state.user;
    },
    getUserAvatarId(state: UserState) {
      return state.user.avatar?.id || "";
    },
    getUserAvatarUrl(state: UserState) {
      return state.user.avatar?.url || "";
    },
    getUserCompanyLogoUrl(state: UserState) {
      return state.user.customer?.logoId?.url || "";
    },
    getUserEmail(state: UserState) {
      return state.user.email;
    },
    getForgotPasswordEmail(state: UserState) {
      return state.email;
    },
    getForgotPasswordEmailTimeout(state: UserState) {
      return state.emailTimeout;
    },
    getUserType(state: UserState) {
      return state.user.type;
    },
    isInternalUserType(state: UserState) {
      return state.user.type === UserTypes.internal;
    },
    isCustomerUserType(state: UserState) {
      return state.user.type === UserTypes.customer;
    },
    isSuperAdminUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.superAdmin);
    },
    isCustomerUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.customer);
    },
    isCustomerServiceUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.customerServiceAdmin);
    },
    isSupplyChainUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.purchaseTeamAdmin);
    },
    isCostingParametersViewerRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.costingParametersViewer);
    },
    isCostingEngineerRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.costingEngineer);
    },
    isTechnicalSolutionEngineerRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.technicalSolutionEngineer);
    },
    isQuoteAuthorizerUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.quoteAuthorizer);
    },
    isCostingEditorUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.costingEditor);
    },
    isAccountManagerRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.accountManager);
    },
    isUploadDataAdminRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.uploadDataAdmin);
    },
    isTechnicalFeedbackViewerRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.technicalFeedbackViewer);
    },
    isDownloadEngineeringReportsRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.downloadEngineeringReports);
    },
    isDownloadEngineeringReportsAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        downloadEngineeringReportsAllowedRoles.includes(role)
      );
    },
    isCostingParametersAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        costingParametersAllowedRoles.includes(role)
      );
    },
    isSendTechnicalFeedbackNotificationAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        sendTechnicalFeedbackNotificationAllowedRoles.includes(role)
      );
    },
    isAddDiscountAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        addDiscountAllowedRoles.includes(role)
      );
    },
    isCostingAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        costingAllowedRoles.includes(role)
      );
    },
    isPartEditAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partEditAllowedRoles.includes(role)
      );
    },
    isPartConfirmationAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partConfirmationAllowedRoles.includes(role)
      );
    },
    isPartInfoAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partInfoAllowedRoles.includes(role)
      );
    },
    isPartDetailsEditAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partDetailsEditAllowedRoles.includes(role)
      );
    },
    isPartDetailsVerifyAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partDetailsVerifyAllowedRoles.includes(role)
      );
    },
    isPartDetailsCostingAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partDetailsCostingAllowedRoles.includes(role)
      );
    },
    isAddAdjustmentAllowedRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        addAdjustmentAllowedRoles.includes(role)
      );
    },
    isTechnicalFeedbackAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        technicalFeedbackAllowedRoles.includes(role)
      );
    },
    isPartDetailsDocumentsAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        partDetailsDocumentsAllowedRoles.includes(role)
      );
    },
    isRfqAllowedAdminRole(state: UserState) {
      return state.user.roles?.some((role) =>
        rfqAllowedAdminRoles.includes(role)
      );
    },
    isReorderQuoteAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        reorderQuoteAllowedRoles.includes(role)
      );
    },
    isCurrencyAllowedAdminRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        currencyAllowedAdminRoles.includes(role)
      );
    },
    isRfqRevisionAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        rfqRevisionAllowedRoles.includes(role)
      );
    },
    isRfqRevisionViewAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        rfqRevisionViewAllowedRoles.includes(role)
      );
    },
    isQuoteAllowedRole(state: UserState) {
      return state.user.roles?.some((role) => quoteAllowedRoles.includes(role));
    },
    isDownloadFilesAllowedAdminRole(state: UserState) {
      return state.user.roles?.some((role) =>
        rfqDownloadFilesAllowedAdminRoles.includes(role)
      );
    },
    isDownloadCostingFileAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        rfqDownloadCostingFileAllowedRoles.includes(role)
      );
    },
    isTechnicalFeedbackOperationAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        technicalFeedbackOperationAllowedRoles.includes(role)
      );
    },
    isTrackOrderAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        trackOrderAllowedRoles.includes(role)
      );
    },
    isTrackOrderTechnicalFeedbackAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        trackOrderTechnicalFeedbackAllowedRoles.includes(role)
      );
    },
    isCustomersAllowedAdminRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        customersAllowedAdminRoles.includes(role)
      );
    },
    isUploadDataAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        uploadDataAllowedRoles.includes(role)
      );
    },
    isManufacturerUserRole(state: UserState) {
      return state.user.roles?.includes(UserRoles.manufacturer);
    },
    isManufacturerUserType(state: UserState) {
      return state.user.type === UserTypes.manufacturer;
    },
    isCheckoutAllowedRole(state: UserState) {
      return state.user.roles?.some((role) =>
        checkoutAllowedRoles.includes(role)
      );
    },
    isTrackOrderCustomerAllowedAdminRole(state: UserState) {
      return state.user.roles?.some((role) =>
        trackOrderCustomerAllowedAdminRoles.includes(role)
      );
    },
    isTrackOrderChatWithManufacturerAllowedAdminRole(state: UserState) {
      return state.user.roles?.some((role) =>
        trackOrderChatWithManufacturerAllowedAdminRoles.includes(role)
      );
    },
    isQuoteDeleteAllowedRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        quoteDeleteAllowedRoles.includes(role)
      );
    },
    isQuoteCreateAllowedRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        quoteCreateAllowedRoles.includes(role)
      );
    },
    analyticsUserParams(state: UserState) {
      return (withName = false) => ({
        type: state.user.type,
        roles: state.user.roles,
        company: state.user.customer?.fullName,
        ...[
          withName
            ? {
                firstName: state.user.firstName,
                lastName: state.user.lastName,
              }
            : [],
        ],
      });
    },
    isCompanyOwner(state: UserState) {
      return (
        state.user.type === UserTypes.customer &&
        state.user.customer?.teamRole === TeamRoles.OWNER
      );
    },
    isSidebarCollapsed(state: UserState) {
      return state.isSidebarCollapsed;
    },
    isViewAsAnotherUserAllowedRoles(state: UserState) {
      return state.user.roles?.some((role) =>
        viewAsAnotherUserAllowedRoles.includes(role)
      );
    },
    isViewAsACustomer(state: UserState) {
      const result =
        state.isViewAsACustomer &&
        state.viewAsACustomerData.customerId &&
        sessionStorage.getItem("viewAs") === UserTypes.customer;
      if (state.isShowViewAsACustomerMessage && !messageReactive.value) {
        state.isShowViewAsACustomerMessage = false;
      }
      if (result && !state.isShowViewAsACustomerMessage) {
        showViewAsACustomerMessage();
      } else if (!result) {
        destroyViewAsACustomerMessage();
      }
      return result;
    },
    getViewAsACustomerData(state: UserState) {
      return state.viewAsACustomerData;
    },
  },
  mutations: {
    setEmail(state: UserState, email: string) {
      state.email = email;
    },
    setEmailTimeout(state: UserState, emailTimeout: string) {
      state.emailTimeout = emailTimeout;
    },
    setAccessToken(state: UserState, accessToken: string) {
      state.accessToken = accessToken;
    },
    setRefreshToken(state: UserState, refreshToken: string) {
      state.refreshToken = refreshToken;
    },
    setRefreshTokenRequest(
      state: UserState,
      refreshTokenRequest: Promise<AxiosResponse> | null
    ) {
      state.refreshTokenRequest = refreshTokenRequest;
    },
    setCurrentUser(state: UserState, user: UserInterface) {
      state.user = user;
      changeIntercom(user, false);
    },
    setAvatar(state: UserState, avatar: { id: string; url: string } | null) {
      state.user.avatar = avatar;
    },
    triggerIsSidebarCollapsed(state: UserState, value: boolean) {
      state.isSidebarCollapsed = value;
    },
    triggerIsViewAsACustomer(
      state: UserState,
      value: SelectedCustomerInterface
    ) {
      state.viewAsACustomerData = value;
      const doesCustomerIdExist = !!value.customerId;

      if (!doesCustomerIdExist) {
        destroyViewAsACustomerMessage();
        sessionStorage.removeItem("viewAs");
      }

      state.isViewAsACustomer =
        doesCustomerIdExist &&
        sessionStorage.getItem("viewAs") === UserTypes.customer;
    },
    triggerIsShowViewAsACustomerMessage(state: UserState, value: boolean) {
      state.isShowViewAsACustomerMessage = value;
    },
    setCompanyLogo(
      state: UserState,
      logoId: { id: string; url: string } | null
    ) {
      if (state.user.customer) {
        state.user.customer.logoId = logoId;
      }
    },
    resetState(state: UserState) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setEmail({ commit }, res) {
      commit("setEmail", res);
    },
    setEmailTimeout({ commit }, res) {
      commit("setEmailTimeout", res);
    },
    setAccessToken({ commit }, res) {
      commit("setAccessToken", res);
    },
    setRefreshToken({ commit }, res) {
      commit("setRefreshToken", res);
    },
    setRefreshTokenRequest({ commit }, res) {
      commit("setRefreshTokenRequest", res);
    },
    setCurrentUser({ commit }, res) {
      commit("setCurrentUser", res);
    },
    setAvatar({ commit }, res) {
      commit("setAvatar", res);
    },
    setCompanyLogo({ commit }, res) {
      commit("setCompanyLogo", res);
    },
    triggerIsSidebarCollapsed({ commit }, res) {
      commit("triggerIsSidebarCollapsed", res);
    },
    triggerIsViewAsACustomer({ commit }, res) {
      commit("triggerIsViewAsACustomer", res);
    },
    triggerIsShowViewAsACustomerMessage({ commit }, res) {
      commit("triggerIsShowViewAsACustomerMessage", res);
    },
    logout({ commit }) {
      commit("resetState");
      logoutIntercom();
    },
  },
};

export default store;
