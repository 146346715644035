import { ref } from "vue";
import { socket } from "@/services/SocketService";
import store from "@/store";

export default () => {
  const activeEventList = ref<string[]>([]);
  const user = store.getters["user/getUser"];
  const addNewRelicPageAction = (name: string, err?: Error) => {
    newrelic.addPageAction(name, {
      firstName: user.firstName,
      lastName: user.lastName,
      type: user.type,
      error: err?.message,
    });
  };

  const connectToSocketEvent = (
    name: string,
    handler: (...args: any[]) => void
  ) => {
    socket.on(name, handler);
    activeEventList.value.push(name);
    console.log("connected to", name);
    addNewRelicPageAction(`connected to ${name}`);
  };
  const disconnectFromSocketEvent = (event: string) => {
    const index = activeEventList.value.indexOf(event);
    if (index !== -1) {
      socket.off(event);
      console.log("disconnected from", event);
      addNewRelicPageAction(`disconnected from ${event}`);
      activeEventList.value.splice(index, 1);
    }
  };

  const disconnectFromAllSocketEvents = () => {
    activeEventList.value.forEach((event) => {
      socket.off(event);
      console.log("disconnected from", event);
      addNewRelicPageAction(`disconnected from ${event}`);
    });
    activeEventList.value = [];
  };

  return {
    activeEventList,
    connectToSocketEvent,
    disconnectFromSocketEvent,
    disconnectFromAllSocketEvents,
    addNewRelicPageAction,
  };
};
