import { App, createApp } from "vue";
import Application from "./App.vue";
//(disabled) import "./registerServiceWorker";
import "./scss/core/main.scss";
import "./scss/core/index.scss";
import "./scss/orders.scss";
import router from "./router";
import store from "./store";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import globalConfig from "@/constants/vue-tel-input/globalConfig";
import "cropperjs/dist/cropper.css";

const app = createApp(Application);

app.use(store);
app.use(router);
app.use(
  VueTelInput as unknown as { install: (app: App, ...options: any[]) => any },
  globalConfig
);
app.mount("#app");
