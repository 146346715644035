import { io } from "socket.io-client";
import store from "@/store";

export const socket = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}`, {
  transports: ["websocket"],
  autoConnect: false,
  auth: (callback) => {
    callback({
      token: `Bearer ${store.getters["user/getAccessToken"]}`,
    });
  },
});

const user = store.getters["user/getUser"];
const addNewRelicPageAction = (name: string, err?: Error) => {
  newrelic.addPageAction(name, {
    firstName: user.firstName,
    lastName: user.lastName,
    type: user.type,
    error: err?.message,
  });
};

socket.on("connect", () => {
  console.log("connected");
  addNewRelicPageAction("websocket connected");
});

socket.on("disconnect", () => {
  console.log("disconnected");
  addNewRelicPageAction("websocket disconnected");
});

socket.on("connect_error", (err) => {
  console.log("connect error");
  addNewRelicPageAction("websocket connect error", err);
});
