import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock($setup["TheAppProvider"], null, {
    default: _withCtx(() => [
      (!($setup.isMobile && $setup.isMobileSize))
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            ($setup.route.name === $setup.RouteName.SignUp)
              ? (_openBlock(), _createBlock($setup["SignUp"], { key: 0 }))
              : (_openBlock(), _createBlock($setup["MainPage"], { key: 1 }))
          ], 64))
    ]),
    _: 1
  }))
}