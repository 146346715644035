import { reactive } from "vue";
import { Module } from "vuex";
import { RootState } from "@/types/store/RootState";
import { RfqPartAttachmentInterface } from "@/types/quote-details/RfqPartAttachmentInterface";

const rfqPartAttachmentState = reactive({
  rfqPartAttachments: [] as RfqPartAttachmentInterface[],
});
export type RfqPartAttachmentState = typeof rfqPartAttachmentState;

const store: Module<RfqPartAttachmentState, RootState> = {
  namespaced: true,
  state: rfqPartAttachmentState,
  getters: {
    getRfqPartAttachments(state: RfqPartAttachmentState) {
      return state.rfqPartAttachments;
    },
    getRfqPartAttachmentById:
      (state: RfqPartAttachmentState) => (rfqPartId: string) => {
        return state.rfqPartAttachments.find(
          (item) => item.rfqPartId === rfqPartId
        );
      },
  },
  mutations: {
    setAttachment(
      state: RfqPartAttachmentState,
      payload: RfqPartAttachmentInterface
    ): void {
      const index = state.rfqPartAttachments.findIndex(
        (item) => item.rfqPartId === payload.rfqPartId
      );
      if (index !== -1) {
        state.rfqPartAttachments[index] = payload;
      } else {
        state.rfqPartAttachments.push(payload);
      }
    },
  },
  actions: {
    setAttachment({ commit }, payload: RfqPartAttachmentInterface): void {
      commit("setAttachment", payload);
    },
  },
};

export default store;
