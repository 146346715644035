import { UserRoles } from "@/enums/UserRoles";

export const partInfoAllowedRoles = [
  UserRoles.superAdmin,
  UserRoles.customerServiceAdmin,
  UserRoles.purchaseTeamAdmin,
  UserRoles.customer,
  UserRoles.manufacturer,
  UserRoles.engineeringTeam,
  UserRoles.costingEditor,
];
