import { reactive } from "vue";
import { Module } from "vuex";
import { RootState } from "@/types/store/RootState";
import CurrencyInterface from "@/types/CurrencyInterface";

const currencyState = reactive({
  currencies: [] as CurrencyInterface[],
});
export type CurrencyState = typeof currencyState;

const store: Module<CurrencyState, RootState> = {
  namespaced: true,
  state: currencyState,
  getters: {
    getCurrencies(state: CurrencyState) {
      return state.currencies;
    },
    getCurrencySign: (state: CurrencyState) => (currencyId: string) => {
      return (
        state.currencies.find((item) => item.id === currencyId)?.sign || "$"
      );
    },
    getCurrencySymbol: (state: CurrencyState) => (currencyId: string) => {
      return (
        state.currencies.find((item) => item.id === currencyId)?.symbol || "USD"
      );
    },
  },
  mutations: {
    setCurrencies(state: CurrencyState, payload: CurrencyInterface[]): void {
      state.currencies = payload;
    },
  },
  actions: {
    setCurrencies({ commit }, payload: CurrencyInterface[]): void {
      commit("setCurrencies", payload);
    },
  },
};

export default store;
