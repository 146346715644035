export enum UserRoles {
  customer = "CUSTOMER",
  manufacturer = "MANUFACTURER",
  superAdmin = "SUPER_ADMIN",
  customerServiceAdmin = "CUSTOMER_SERVICE_ADMIN",
  // purchase team admin is the same as supply chain admin
  purchaseTeamAdmin = "PURCHASE_TEAM_ADMIN",
  costingEngineer = "COSTING_ENGINEER",
  technicalSolutionEngineer = "TECHNICAL_SOLUTION_ENGINEER",
  quoteAuthorizer = "QUOTE_AUTHORIZER",
  accountManager = "ACCOUNT_MANAGER",
  quoteViewer = "QUOTE_VIEWER",
  uploadDataAdmin = "UPLOAD_DATA_ADMIN",
  engineeringTeam = "ENGINEERING_TEAM",
  technicalFeedbackViewer = "TECHNICAL_FEEDBACK_VIEWER",
  costingParametersEditor = "COSTING_PARAMETERS_EDITOR",
  costingParametersViewer = "COSTING_PARAMETERS_VIEWER",
  costingViewer = "COSTING_VIEWER",
  costingEditor = "COSTING_EDITOR",
  discountEditor = "DISCOUNTS_EDITOR",
  downloadEngineeringReports = "DOWNLOAD_ENGINEERING_REPORTS",
}
