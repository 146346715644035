import { computed, h, ref } from "vue";
import { MessageRenderMessage, NAlert, NIcon } from "naive-ui";
import type { MessageReactive } from "naive-ui";
import store from "@/store";
import Eye from "@/assets/icons/eye-current-color.svg?inline";

export default () => {
  const viewAsACustomerData = computed(
    () => store.getters["user/getViewAsACustomerData"]
  );

  const messageReactive = ref<MessageReactive | null>(null);
  const showViewAsACustomerMessage = () => {
    if (messageReactive.value && !!viewAsACustomerData.value.customerId) {
      destroyViewAsACustomerMessage();
    } else if (!messageReactive.value && viewAsACustomerData.value.customerId) {
      messageReactive.value = window.$message.info("", {
        render: (props) => {
          return renderMessage(props);
        },
        duration: 0,
      });
    }
    store.dispatch(
      "user/triggerIsShowViewAsACustomerMessage",
      !!messageReactive.value
    );
  };

  const destroyViewAsACustomerMessage = () => {
    messageReactive.value?.destroy();
    messageReactive.value = null;
  };

  const renderMessage: MessageRenderMessage = (props) => {
    const { type } = props;
    return h(
      NAlert,
      {
        showIcon: false,
        closable: true,
        onClose: () => {
          store.dispatch("user/triggerIsViewAsACustomer", {});
          window.close();
        },
        type: type === "loading" ? "default" : type,
        class: ["custom-data-toasted", "view-as-a-customer"],
      },
      {
        default: () => {
          const { customerNameWithoutNumber, userName } =
            viewAsACustomerData.value;
          return h(
            "div",
            {
              class: "view-as-a-customer-content",
            },
            [
              h(NIcon, { size: 24, class: "eye-icon" }, () =>
                h(Eye, { size: 25 })
              ),
              h("span", [
                `Viewing as: ${customerNameWithoutNumber} / ${userName}`,
              ]),
            ]
          );
        },
      }
    );
  };

  return {
    showViewAsACustomerMessage,
    destroyViewAsACustomerMessage,
    messageReactive,
  };
};
